import * as React from "react";
import { SVGProps } from "react";

export function InformationTriangleIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M8 1L1 14H15L8 1Z"
                fill="#FBBF24"
                stroke="#FBBF24"
                strokeWidth="1.5"
                strokeLinejoin="round"
            />
            <circle cx="8" cy="6" r="1" fill="white" />
            <rect x="7" y="8" width="2" height="5" rx="1" fill="white" />
        </svg>
    );
}
